var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.BRow,[_c(_setup.BCol,{attrs:{"md":"3"}},[_c(_setup.FilterView,{ref:"filterView",attrs:{"form":_setup.filterForm}})],1),_c(_setup.BCol,{attrs:{"md":"9"}},[_c(_setup.DateRangeFilter,{ref:"dateRangeFilter",attrs:{"form":_setup.dateFilter,"is-comparative-view":_setup.isComparative,"is-processing":_setup.isProcessing,"hide-export":_setup.hideExportInDatePicker},on:{"exportData":_setup.handleExport,"applyFilter":function($event){_setup.applyFilterCounter ++}}}),(_setup.filterForm.classroom)?_c('div',{staticClass:"row mt-2"},_vm._l((_setup.reportCounts),function(i,index){return _c('div',{key:i,class:_setup.reportCounts.length == 1 ? 'col-12' : 'col-6'},[(_setup.filterForm.reportType === 'proficiency')?_c(_setup.ProficiencyReport,{ref:"reportView",refInFor:true,attrs:{"form":_setup.filterForm,"dates":{
                             startDate: _setup.dateFilter[`startDate${index + 1}`],
                             endDate: _setup.dateFilter[`endDate${index + 1}`]
                           },"filter-counter":_setup.applyFilterCounter},on:{"updateProcessing":v => _setup.isProcessing = v}}):(_setup.filterForm.reportType === 'lesson_completion')?_c(_setup.LessonCompleteReport,{ref:"reportView",refInFor:true,attrs:{"form":_setup.filterForm,"dates":{
                                startDate: _setup.dateFilter[`startDate${index + 1}`],
                                endDate: _setup.dateFilter[`endDate${index + 1}`]
                              },"filter-counter":_setup.applyFilterCounter},on:{"updateProcessing":v => _setup.isProcessing = v}}):_vm._e()],1)}),0):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }