var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.ScoreReport,{key:_vm.filterCounter,attrs:{"class-room":{
      id: _setup.props.form.classroom,
    },"extra-params":{
      start_date: _setup.props.dates.startDate,
      end_date: _setup.props.dates.endDate,
      student_ids: _setup.props.form.students,
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }