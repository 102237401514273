<template>
  <BOverlay :show="isProcessing">
    <b-card body-class="p-0 m-0">
      <vue-apex-charts
        v-if="form.graphType === 'bar'"
        height="800"
        type="bar"
        :options="options"
        :series="series"
      />
      <div v-else-if="form.graphType === 'table'"
           :set="dataItem = getReportData().data"
      >
        <div v-if="dataItem.length === 0"
             class="p-2"
        >
          <h4>
            No Data Found
          </h4>
        </div>
        <b-table v-else
                 :items="getReportData().data"
        />
      </div>
    </b-card>
  </BOverlay>
</template>
<script setup>
// import useJwt from '@/auth/jwt/useJwt'
import {
  watch, getCurrentInstance, computed, ref,
} from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import { LessonCompletingCalc } from '@/views/v2/common/class/GroupReport/helper/reportHelper'
import VueApexCharts from 'vue-apexcharts'
import { BOverlay, BTable, BCard } from 'bootstrap-vue'

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  dates: {
    type: Object,
    required: true,
  },
  filterCounter: {
    type: Number,
    default: 0,
  },
})

const emits = defineEmits(['updateProcessing'])
const isProcessing = ref(false)
const scores = ref([])
const categoriesData = ref([])

const series = computed(() => [
  {
    name: 'series-1',
    data: scores.value,
  },
])

const options = computed(() => ({
  colors: ['#28c76f'],
  chart: {
    id: 'vuechart-example',
  },
  xaxis: {
    categories: categoriesData.value,
  },
  dataLabels: {
    formatter(val) {
      return `${val}%`
    },
  },
}))

const root = getCurrentInstance().proxy.$root

const fetchReportData = () => {
  isProcessing.value = true
  emits('updateProcessing', true)
  useJwt.getProgressReport(props.form.classroom, props.form.reportType, {
    params: {
      start_date: props.dates.startDate,
      end_date: props.dates.endDate,
      student_ids: props.form.students,
    },
  }).then(res => {
    const lessonDoneReport = res.data.data.lessonDone.score
    const expectedLessonDone = res.data.data.lessonDone.expected
    const { categories, totalScore } = LessonCompletingCalc({ lessonDoneReport, expectedLessonDone }, root)
    scores.value = totalScore
    categoriesData.value = categories
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    isProcessing.value = false
    emits('updateProcessing', false)
  })
}

watch(() => props.filterCounter, () => {
  fetchReportData()
})

// ! the function is responsible to export the data
// eslint-disable-next-line no-unused-vars
const getReportData = () => {
  const requiredData = []
  categoriesData.value.forEach((v, index) => {
    requiredData.push({
      student: v,
      score: scores.value[index],
    })
  })
  return {
    fileName: 'lesson_completion',
    data: requiredData,
  }
}
</script>
